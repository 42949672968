import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Component as Input } from './Input';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <p>{`문자열을 입력받는 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Input} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Input placeholder=\"입력해주세요.\" />\n<br />\n<Input size=\"sm\" placeholder=\"입력해주세요.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="입력해주세요." mdxType="Input" />
  <br />
  <Input size="sm" placeholder="입력해주세요." mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <p>{`기본 사이즈는 `}<inlineCode parentName="p">{`md`}</inlineCode>{`입니다.`}</p>
    <blockquote>
      <p parentName="blockquote">{`class101 제품에서 `}<inlineCode parentName="p">{`sm`}</inlineCode>{` 사이즈는 폼이 많은 관리자 단에서만 사용해주세요.`}</p>
    </blockquote>
    <Playground __position={2} __code={'<Input size=\"md\" />\n<br />\n<Input size=\"sm\" />\n<br />\n<Input size=\"md\" fill={false} />\n<br />\n<Input size=\"sm\" fill={false} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input size="md" mdxType="Input" />
  <br />
  <Input size="sm" mdxType="Input" />
  <br />
  <Input size="md" fill={false} mdxType="Input" />
  <br />
  <Input size="sm" fill={false} mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "inline-inputs"
    }}>{`Inline inputs`}</h2>
    <Playground __position={3} __code={'<Input inline placeholder=\"입력해주세요.\" />\n<Input inline placeholder=\"입력해주세요.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input inline placeholder="입력해주세요." mdxType="Input" />
  <Input inline placeholder="입력해주세요." mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "with-label"
    }}>{`With label`}</h2>
    <Playground __position={4} __code={'<Input label=\"제목\" />\n<Input label=\"주소\" size=\"sm\" inline />\n<Input label=\"전화번호\" size=\"sm\" inline />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input label="제목" mdxType="Input" />
  <Input label="주소" size="sm" inline mdxType="Input" />
  <Input label="전화번호" size="sm" inline mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "with-allow-message"
    }}>{`With allow message`}</h2>
    <Playground __position={5} __code={'<Input allowMessage=\"올바른 값입니다.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input allowMessage="올바른 값입니다." mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "with-error-message"
    }}>{`With error message`}</h2>
    <Playground __position={6} __code={'<Input errorMessage=\"특수문자를 제외하고 입력해주세요.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input errorMessage="특수문자를 제외하고 입력해주세요." mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "with-warn-message"
    }}>{`With warn message`}</h2>
    <Playground __position={7} __code={'<Input warnMessage=\"보안에 취약한 값입니다.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input warnMessage="보안에 취약한 값입니다." mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "disabled-input"
    }}>{`Disabled input`}</h2>
    <Playground __position={8} __code={'<Input placeholder=\"잠금 상태입니다.\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="잠금 상태입니다." disabled mdxType="Input" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      